import { Injectable } from "@angular/core";
import { NotificationService } from '../../services/notification.service';

@Injectable()
export class NotifyService {
    constructor(
        private notificationService: NotificationService
    ) {}

    public showError = error => {
        this.notificationService.smartMessageBox({
            title: error.message,
            buttons: '[Ok]'
        });
    };

    public snackNotify = (message, options: any = {}) => {
        let config = {
            title: 'Notification',
            content: message,
            color: "green",
            timeout: 7000,
            icon: "fa fa-info"
        }
        Object.assign(config, options)
        this.notificationService.smallBox(config);        
    };
}
