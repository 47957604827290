import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { WebsocketModule } from './shared/websocket';
import { AppGlobals } from './app.globals';
import { environment } from '@env/environment';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        WebsocketModule.config({
            url: environment.smartadmin.ws
        }),
        AppRoutingModule,
        SharedModule,
        CoreModule
    ],
    providers: [AppGlobals],
    bootstrap: [AppComponent]
})
export class AppModule { }
