import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class AppGlobals {
    geo: any;

    readonly userLang: string = navigator.language || navigator['userLanguage'];
    readonly phoneMask: string = this.userLang == 'ru' ? '+0-000-000-0000' : '000-000-0000';
    readonly phoneLength: number = this.userLang == 'ru' ? 11 : 10;

    get getGeolocation(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (environment.smartadmin.ipstack && environment.smartadmin.ipstack.apikey !== '') {
                if (this.geo) {
                    resolve(this.geo);
                } else {
                    let apikey = environment.smartadmin.ipstack.apikey;
                    $.get('//api.ipstack.com/check?access_key='+apikey, data => {
                        if (data && data.country_code) {
                            this.geo = data;
                            resolve(this.geo);
                        }
                    });
                }
            } else {
                resolve(false);
            }
        });
    } 
}