import { Directive, Input, Output, ElementRef, OnInit, EventEmitter } from "@angular/core";

import "script-loader!jquery.maskedinput/src/jquery.maskedinput.js";

@Directive({
    selector: "[saMaskedInput]"
})
export class MaskedInput implements OnInit {
    @Input() saMaskedInput: string;
    @Input() saMaskedOptions: string;
    @Output() saMaskedCompleted = new EventEmitter<any>();
    @Output() saMaskedChange = new EventEmitter<any>();

    private prevValue: string = '';
        
    constructor(private el: ElementRef) { }

    ngOnInit() {
        let this_ = this;
        let options = Object.assign({
            autoclear: true,
            placeholder: '_'
        }, this.saMaskedOptions);
        options['completed'] = function() {
            this_.saMaskedCompleted.emit(this.val());
        };
        $.mask.definitions['~'] = '[+]';
        $(this.el.nativeElement).mask(this.saMaskedInput, options);
        this.prevValue = $(this.el.nativeElement).val() || '';
        $(this.el.nativeElement).on('keyup', function() {
            this_.saMaskedChange.emit(this.value);
        });
        $(this.el.nativeElement).on('focusout', function() {
            var value = !this.value.match(/_/) ? this.value : '';
            this_.saMaskedChange.emit(value);
        });
    }
}