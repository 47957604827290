import { Directive, Input, ElementRef, OnInit, HostListener } from "@angular/core";
import { FormGroup, FormArray } from '@angular/forms';

@Directive({
    selector: "[ReactiveValidator]"
})
export class ReactiveValidatorDirective implements OnInit {
    @Input() ReactiveValidator: any = {};
    errorsOnChange = {};
    onlyDirty: boolean = true;
    private oldValues: any = {};

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.ReactiveValidator.form.valueChanges.subscribe(values => {
            if (JSON.stringify(values).toLowerCase() !== JSON.stringify(this.oldValues).toLowerCase()) {
                this.oldValues = values;
                this.onValueChanged(values);
                this.showErrors();
            }
        });
    }
    
    @HostListener('submit') onSubmit() {
        const formGroup = this.ReactiveValidator.form;
        if (formGroup.invalid) {
            this.onlyDirty = false;
            this.onValueChanged();
            this.showErrors();
        }
    }
    
    showFieldError(field: string, msg: string = '') {
        const control = this.el.nativeElement.querySelector('[formcontrolname="'+field+'"], [id="'+field+'"]');
        if (control) {
            const parent = control.parentElement.parentElement;
            if (msg && msg !== '') {
                parent.classList.add('has-error');
                parent.insertAdjacentHTML('beforeend', '<span class="help-block"><i class="fa fa-warning"></i> '+msg+'</span>');
            }
        }
    }
    
    showErrors() {
        this.el.nativeElement.querySelectorAll('.has-error').forEach(e => e.classList.remove('has-error'));
        this.el.nativeElement.querySelectorAll('span.help-block').forEach(e => e.parentNode.removeChild(e));
        for (const field in this.errorsOnChange) {
            if (this.errorsOnChange[field] instanceof Object) {
                for (const childField in this.errorsOnChange[field]) {
                    this.showFieldError(childField, this.errorsOnChange[field][childField]);
                }
            } else {
                this.showFieldError(field, this.errorsOnChange[field]);
            }
        }
    }
    
    onValueChanged(data?: any, group?: string) {
        if (!this.ReactiveValidator.form) { return; }
        const form = this.ReactiveValidator.form;
        const validationMessages = this.ReactiveValidator.validationMessages;
        for (const field in validationMessages) {
            const control = form.get(field);
            if (control instanceof FormArray) {
                this.errorsOnChange[field] = [];
                for (let formGroup of control.controls) {
                    let errors = {};
                    for (const field1 in validationMessages[field]) {
                        const control1 = formGroup.get(field1);
                        if (control1 && (control1.dirty || !this.onlyDirty) && !control1.valid) {
                            const messages = validationMessages[field][field1];
                            errors[field1] = '';
                            for (const key in control1.errors) {
                                errors[field1] += messages[key] + ' ';
                            }
                        }
                    }
                    this.errorsOnChange[field].push(errors);
                }
            } else if (control instanceof FormGroup && (!group || (group && field === group))) {
                this.errorsOnChange[field] = {};
                for (const field1 in validationMessages[field]) {
                    const control1 = control.get(field1);
                    if (control1 && (control1.dirty || !this.onlyDirty) && !control1.valid) {
                        const messages = validationMessages[field][field1];
                        this.errorsOnChange[field][field1] = '';
                        for (const key in control1.errors) {
                            this.errorsOnChange[field][field1] += messages[key] + ' ';
                        }
                    }
                }
            } else {
                this.errorsOnChange[field] = '';
                if (control && (control.dirty || !this.onlyDirty) && !control.valid) {
                    const messages = validationMessages[field];
                    for (const key in control.errors) {
                        this.errorsOnChange[field] += messages[key] + ' ';
                    }
                }
            }
        }
    }
}