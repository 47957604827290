import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiValidateDirective } from "./ui-validate.directive";
import { BootstrapValidatorDirective } from "./bootstrap-validator.directive";
import { ReactiveValidatorDirective } from "./reactive-validator.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        UiValidateDirective,
        BootstrapValidatorDirective,
        ReactiveValidatorDirective
    ],
    exports: [
        UiValidateDirective,
        BootstrapValidatorDirective,
        ReactiveValidatorDirective
    ]
})
export class SmartadminValidationModule { }
