export const placeholder = 'Select One...';

export class BaseList {
    loading: Promise<any>;
    api: string  = '';
    itemType: string = 'any';
    list: any[] = [];
    emptyOption: boolean = true;
    total: number = 0;

    fill(data: any) {
        this.total = ~~(data && data.total) || 0;
        let list = (data && data.list) || data;
        for (var i = 0; i < list.length; i++) {
            let item = new BaseModels[this.itemType](list[i]);
            this.list.push(item);
            if (!data.total) {
                this.total++;
            }
        }
        this.list.sort((i1, i2) => {
            let p1 = '', p2 = '';
            switch (this.itemType) {
                case 'oState':
                case 'oNameCode':
                    p1 = i1.code;
                    p2 = i2.code;
                    break
                case 'oLookup':
                    p1 = i1.order;
                    p2 = i2.order;
                    break
                default:
                    p1 = i1.name;
                    p2 = i2.name;
            }
            if (p1 > p2) { return 1; }
            if (p1 < p2) { return -1; }
            return 0;
        });
        if (this.emptyOption) {
            this.list.unshift(new BaseModels[this.itemType]({ name: placeholder }));
        }
    }
}

namespace BaseModels {
    export class oNameCode {
        id: string;
        name: string;
        code: string;

        getValue() {
            return this.id;
        }

        getLabel() {
            if (this.code !== '') {
                return this.code +' - '+ this.name;
            }
            return this.name;
        }

        constructor(data?: any) {
            this.id = data && data.id || '';
            this.name = data && data.name || '';
            this.code = data && data.code || '';
        }
    }
    
    export class oState {
        id: string;
        name: string;
        code: string;
        country: oNameCode;

        getValue() {
            return this.id;
        }

        getLabel() {
            if (this.code !== '') {
                return this.code +' - '+ this.name;
            }
            return this.name;
        }

        constructor(data?: any) {
            this.id = data && data.id || '';
            this.name = data && data.name || '';
            this.code = data && data.code || '';
            this.country = new oNameCode(data && data.country || {});
        }
    }
    
    export class oCity {
        id: string;
        state: oState;
        name: string;

        getValue() {
            return this.id;
        }

        getLabel() {
            return this.name;
        }

        constructor(data?: any) {
            this.id = data && data.id || '';
            this.name = data && data.name || '';
            this.state = new oState(data && data.state || {});
        }
    }
    
    export class oLookup {
        id: string;
        name: string;
        order: number;
        code: string;
        parentId: number;

        getValue() {
            return this.id;
        }

        getLabel() {
            return this.name;
        }

        constructor(data?: any) {
            this.id = data && data.id || '';
            this.name = data && data.name || '';
            this.order = data && ~~data.order || 0;
            this.code = data && data.code || '';
            this.parentId = data && ~~data.parentId || 0;
        }
    }
    
    export class oDealership {
        id: string;
        name: string;

        getValue() {
            return this.id;
        }

        getLabel() {
            return this.name;
        }

        constructor(data?: any) {
            this.id = data && data.id || '';
            this.name = data && data.name || '';
        }
    }
}

export class Countries extends BaseList {
    api: string = 'countries';
    list: BaseModels.oNameCode[] = [];
    itemType: string = 'oNameCode';
}

export class States extends BaseList {
    api: string = 'countries/{id}/state?offset=0&limit=100';
    list: BaseModels.oState[] = [];
    itemType: string = 'oState';
    
    constructor(countryID?: string) {
        super();
        if (countryID) {
            this.api = this.api.replace(/{id}/, countryID);
        }
    }
}

export class Cities extends BaseList {
    api: string = 'cities?stateId={id}';
    list: BaseModels.oCity[] = [];
    itemType: string = 'oCity';
    
    constructor(args?: any) {
        super();
        if (args && args.stateID) {
            this.api = this.api.replace(/{id}/, args.stateID);
        }
        if (args && args.query) {
            this.api += '&'+args.query;
        }
        this.emptyOption = false;
    }
}

export class Lookups extends BaseList {
    api: string = 'lookups/{type}';
    list: BaseModels.oLookup[] = [];
    itemType: string = 'oLookup';
    
    constructor(args?: any) {
        super();
        if (args && args.type) {
            this.api = this.api.replace(/{type}/, args.type);
        }
        if (args && args.query) {
            this.api += '?'+args.query;
        }
        this.emptyOption = false;
    }
}

export class Dealerships extends BaseList {
    api: string = 'dealerships';
    list: BaseModels.oDealership[] = [];
    itemType: string = 'oDealership';
    
    constructor(args?: any) {
        super();
        if (args && args.query) {
            this.api += '?'+args.query;
        }
        this.emptyOption = false;
    }
}
