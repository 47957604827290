import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'carzaz-chat',
    template: '<div class="etsChatButton"></div>',
    encapsulation: ViewEncapsulation.None
})
export class CarzazChatComponent {
    private scripts: any = {
        'carzaz-chat': {
            loaded: false,
            src: '//cdn.engagetosell.com/js/13542.js'
        }
    };

    constructor() {
        this.loadScript('carzaz-chat').then(script => {});
    }

    loadScript(name: string) {
        return new Promise((resolve, reject) => {
            if (!this.scripts[name].loaded) {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script['readyState']) {  //IE
                    script['onreadystatechange'] = () => {
                        if (script['readyState'] === "loaded" || script['readyState'] === "complete") {
                            script['onreadystatechange'] = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            } else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    }
}