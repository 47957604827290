import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { JsonApiService } from "@app/core/services/json-api.service";
import { Subscription } from 'rxjs-compat/Subscription';
import { WebsocketService } from '@app/shared/websocket/websocket.service';
import { Store } from '@ngrx/store';
import { SnackNotify } from '@app/core/store/notify/notify.actions';
import { NotifyState } from '@app/core/store/notify/notify.reducer';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnDestroy {
    notifications: any[] = [];
    currentUser: any = {};
    limitNotifs: number = 10;
    offsetNotifs: number = 0;
    totalNotifs: number = -1;
    isLoadedNotifs: boolean = false;
    private textMessages: Subscription;
    
    constructor(
        private jsonApiService: JsonApiService,
        private userService: UserService,
        private wsService: WebsocketService,
        private store: Store<NotifyState>
    ) {
        this.userService.user$.subscribe(user => {
            this.currentUser = user;
            this.reloadNotifs();
        });
        this.textMessages = this.wsService.on('text-notification').subscribe(data => {
            this.store.dispatch(new SnackNotify({ message: data.payload.body, options: { timeout: null } }));
            this.notifications.unshift(data.payload.body);
        });
    }

    reloadNotifs(){
        this.offsetNotifs = 0;
        this.totalNotifs = -1;
        this.getNotifs();
    }
    
    getNotifs() {
        if (this.totalNotifs < 0 || this.offsetNotifs < this.totalNotifs) {
            this.isLoadedNotifs = false;
            let params = [];
            params.push('limit=' + this.limitNotifs);
            params.push('offset=' + this.offsetNotifs);
            let offset = this.offsetNotifs;
            this.jsonApiService.fetch('notification-history?'+params.join('&')).subscribe((notifs: any) => {

                notifs.list = notifs.list.map(item => {                    
                    return {
                        id: item.id,
                        body: item.body,
                        createdAt: item.createdAt,
                        timestamp: '',
                        showHeader: false,
                        headerName: null,
                        isToday: false,
                        isYesterday: false,
                        isOlder: false
                    };
                });
                this.notifications = this.notifications.concat([ ...notifs.list ]);
                for (let i = this.offsetNotifs; i < this.notifications.length; ++i){
                    let timestamp = new Date(Date.parse(this.notifications[i].createdAt));
                    let datestamp = new Date(timestamp).setHours(0,0,0,0);
                    if (datestamp == new Date().setHours(0,0,0,0)){
                        this.notifications[i].isToday = true;
                        this.notifications[i].timestamp = timestamp.toLocaleTimeString() + ":";
                        if (i == 0){
                            this.notifications[i].showHeader = true;
                            this.notifications[i].headerName = "TODAY";
                        }
                    } else if (datestamp + 86400000 == new Date().setHours(0,0,0,0)){
                        this.notifications[i].isYesterday = true;
                        this.notifications[i].timestamp = timestamp.toLocaleTimeString() + ":";
                        if (i == 0 || !this.notifications[i - 1].isYesterday){
                            this.notifications[i].showHeader = true;
                            this.notifications[i].headerName = "YESTERDAY";
                        }
                    } else {
                        this.notifications[i].isOlder = true;
                        this.notifications[i].timestamp = timestamp.toLocaleDateString() + " " + timestamp.toLocaleTimeString() + ":";
                        if (i != 0 && !this.notifications[i - 1].isOlder){
                            this.notifications[i].showHeader = true;
                            this.notifications[i].headerName = "OLDER";
                        }
                    }
                }
                this.totalNotifs = notifs.total;
                this.offsetNotifs += this.limitNotifs;
                this.isLoadedNotifs = true;
            }, () => this.isLoadedNotifs = true);
        }
    }

    removeNotification(notification){
        this.jsonApiService.delete('notification-history/' + notification.id).subscribe((response: any) => {
            var idx = this.notifications.findIndex(z => z.id == notification.id);
            if (this.notifications.length > idx + 1 && this.notifications[idx].showHeader && !this.notifications[idx + 1].showHeader){
                this.notifications[idx+1].showHeader = true;
                this.notifications[idx+1].headerName = this.notifications[idx].headerName;
            }
            this.notifications.splice(idx, 1);    
        });
    }

    toggleHeader(notification){
        notification.showTimestamp = !notification.showTimestamp;
    }
    
    onScrollNotifs() {
        if (this.isLoadedNotifs) {
            this.getNotifs();
        }
    }
    
    ngOnDestroy() {
        this.textMessages.unsubscribe();
    }
}