import { Directive, ElementRef, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { addClassName, removeClassName } from "../../../utils/dom-helpers";
import "select2/dist/js/select2.min.js";
import { ListService } from '@app/core/services/list/list.service';

@Directive({
    selector: "[select2]"
})
export class Select2Directive implements OnInit, OnChanges {
    @Input() options = {};
    @Input() value = '';
    @Output() selected = new EventEmitter<any>();
    
    private prevTerm = '';

    constructor(
        private el: ElementRef,
        private listService: ListService
    ) {
        addClassName(this.el.nativeElement, ["sa-cloak", "sa-hidden"]);
    }

    ngOnChanges(changes: SimpleChanges) {
        setTimeout(() => {
            if (changes['value']) {
                let parsedValue = changes['value'].currentValue ? changes['value'].currentValue.split('|') : '';
                if (parsedValue == '') {
                    $(this.el.nativeElement).val(null).trigger('change');
                } else {
                    let option = $(this.el.nativeElement).find("option[value='" + parsedValue[0] + "']");
                    if (option.length && option.text() == parsedValue[1]) {
                        $(this.el.nativeElement).val(parsedValue[0]).trigger('change');
                    } else { 
                        $(this.el.nativeElement).find('option').remove();
                        $(this.el.nativeElement).prepend(new Option(parsedValue[1] || parsedValue[0], parsedValue[0], false, false)).trigger('change');
                    }
                }
            }
        }, 0);
    }

    ngOnInit() {
        let options = {};
        if (this.options['tags']) {
            options['tags'] = this.options['tags'];
        }
        if (this.options['listName']) {
            options['ajax'] = {
                delay: 300,
                data: function(params) {
                    if (params.term !== this.prevTerm) {
                        params.page = 1;
                    }
                    this.prevTerm = params.term;
                    return {
                        search: params.term,
                        page: params.page || 1,
                        per_page: 50
                    };
                }.bind({ prevTerm: this.prevTerm }), 
                transport: function(params, success, failure) {
                    let listArgs = Object.assign({}, this.options['listArgs']);
                    let queryParams = Object.assign({
                            offset: (params.data.page - 1) * params.data.per_page,
                            limit: params.data.per_page
                        }, listArgs['query']);
                    if (params.data.search) {
                        queryParams['name'] = params.data.search;
                        queryParams['search'] = params.data.search;
                    }
                    listArgs['query'] = Object.keys(queryParams).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])).join('&');
                    this.listService.getList(this.options['listName'], listArgs).loading.then(list => {
                        success({ list: list, params: params.data });
                    });
                }.bind(this),
                processResults: function(data, params) {
                    let results = [];
                    let more = false;
                    if (data && data.list) {
                        for (const item of data.list.list) {
                            results.push({
                                id: item.getValue(),
                                text: item.getLabel()
                            });
                        }
                        more = (data.params.page * data.params.per_page) < data.list.total;
                    }
                    return {
                        results: results,
                        pagination: {
                            more: more
                        }
                    };
                }
            }
        }
        $(this.el.nativeElement).select2(options);
        removeClassName(this.el.nativeElement, ["sa-hidden"]);
        $(this.el.nativeElement).on('select2:select', (e) => {
            if (e.target.type === 'select-multiple') {
                var values = [];
                for (var i = 0; i < e.target.selectedOptions.length; i++) {
                    values.push(e.target.selectedOptions[i].value);
                }
                this.selected.emit(values);
            } else {
                this.selected.emit([e.params.data.id, e.params.data.text].join('|'));
            }
        });
    }
}