import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SmartadminValidationModule } from '@app/shared/forms/validation/smartadmin-validation.module';
import { SettingsComponent } from "./settings.component";
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SmartadminValidationModule,
        NgxMaskModule.forRoot()
    ],
    declarations: [
        SettingsComponent
    ],
    exports: [
        SettingsComponent
    ]
})
export class SettingsModule { }
