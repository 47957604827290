import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser'

import { PopoverModule } from "ngx-bootstrap/popover";

import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";
import { RecentProjectsComponent } from "./recent-projects/recent-projects.component";
import { FullScreenComponent } from "./full-screen/full-screen.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { MessagesComponent } from "./messages/messages.component";
import { FileUploadModule } from '@app/shared/components/fileupload/fileupload.module';

import { ActivitiesComponent } from "./activities/activities.component";
import { ActivitiesMessageComponent } from "./activities/activities-message/activities-message.component";
import { ActivitiesNotificationComponent } from "./activities/activities-notification/activities-notification.component";
import { ActivitiesTaskComponent } from "./activities/activities-task/activities-task.component";
import { HeaderComponent } from "./header.component";

import { UtilsModule } from "@app/shared/utils/utils.module";
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { I18nModule } from "@app/shared/i18n/i18n.module";
import { UserModule } from "@app/shared/user/user.module";
import { VoiceControlModule } from "@app/shared/voice-control/voice-control.module";
import { BsDropdownModule } from "ngx-bootstrap";

import { SmartadminInputModule } from "@app/shared/forms/input/smartadmin-input.module";
import { SettingsModule } from '@app/shared/components/settings/settings.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        VoiceControlModule,
        BsDropdownModule,
        UtilsModule, PipesModule, I18nModule, UserModule, PopoverModule.forRoot(),
        SmartadminInputModule,
        SettingsModule,
        InfiniteScrollModule,
        FileUploadModule
    ],
    declarations: [
        ActivitiesMessageComponent,
        ActivitiesNotificationComponent,
        ActivitiesTaskComponent,
        RecentProjectsComponent,
        FullScreenComponent,
        CollapseMenuComponent,
        ActivitiesComponent,
        HeaderComponent,
        NotificationsComponent,
        MessagesComponent,
        SafeHtmlPipe
    ],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule { }
