import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { BodyService } from "@app/core/services/body.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  loaderStatus: boolean = false;

  constructor(private bodyService: BodyService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.method !== "GET" && request.url.search("user/storage") < 0 && !request.params.has("silent") && this.bodyService.hasClass('loaded')) {
      this.showLoader();
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && request.method !== "GET") {
            this.hideLoader();
          }
        },
        (err: any) => this.hideLoader(true)
      )
    );
  }

  private showLoader(): void {
    this.bodyService.removeClass("loaded");
    this.loaderStatus = true;
  }

  private hideLoader(force?: boolean): void {
    if (this.loaderStatus || force) {
      this.bodyService.addClass("loaded");
      this.loaderStatus = false;
    }
  }
}
