import { FormControl, ValidatorFn } from '@angular/forms';

export function PasswordValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSpec = /[!@#$%^&*_-]/.test(control.value);
        let hasLength = control.value.length >= 6;
        const valid = (hasNumber && hasUpper && hasLower && hasSpec && hasLength) || control.value == '';
        
        return !valid ? { strong: { value: true } } : null;
    };
}