import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap, filter, switchMap, take, catchError } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as actions from './auth.actions';
import { AuthState } from './auth.reducer';
import { getLoggedIn } from './auth.selectors';
import { SnackNotify } from '../notify/notify.actions';
import { NotifyState } from '../notify/notify.reducer';
import { AuthTokenService } from '@app/core/services/auth-token.service';
import { AuthService } from '@app/core/services/auth.service';
import { BodyService } from '@app/core/services/body.service';
import { StorageService } from '@app/core/services/storage.service';
import { WebsocketService } from '@app/shared/websocket/websocket.service';

@Injectable()
export class AuthEffects {
    redirectUrl: string = '/';
    loginUrl: string = '/auth/welcome';

    dispatchError = (err: any) => {
        this.storeAuth.dispatch(
            new actions.AuthFailure({
                code: err.code,
                message: err.message
            })
        );
        this.authToken.token = null;
    };

    @Effect({ dispatch: false })
    authInit$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.AuthInit),
        tap((data: any) => {
            this.bodyService.addClass('loaded');
        })
    );

    @Effect({ dispatch: false })
    login$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.LoginAction),
        switchMap((data: any) => { 
            if (data.payload.redirect) {
                this.redirectUrl = data.payload.redirect;
            }
            let request: Observable<any>;
            if (data.payload.accessToken) {
                request = this.auth.signInWithFB(data.payload);
            } else {
                request = this.auth.signInWithEmailAndPassword(data.payload.login, data.payload.password, data.payload.remember);
            }
            return request.pipe(
                tap((data: any) => {
                    this.storage.set('JUST_LOGGED_IN', true);
                }),
                catchError(err => of(this.dispatchError(err)))
            )
        })
    );

    @Effect({ dispatch: false })
    logout$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.LogoutAction),
        switchMap((data: any) => {
//            this.wsService.logout();
            if (data.payload && data.payload.withoutRequest) {
                return of(true).pipe(
                    tap(() => {
                        this.authToken.dumpToken(null).then(() => {
                            this.redirectUrl = '/';
                            this.router.navigate([this.loginUrl]).then(() => location.reload());
                        });
                    })
                );
            }
            return this.auth.signOut().pipe(
                map(() => {
                    this.authToken.dumpToken(null).then(() => {
                        this.redirectUrl = '/';
                        this.router.navigate([this.loginUrl]).then(() => location.reload());
                    });
                }),
                catchError(this.dispatchError.bind(this))
            )
        })
    );

    @Effect({ dispatch: false })
    signup$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.SignupAction),
        switchMap((data: any) => {
            let request: Observable<any>;
            if (data.payload.accessToken) {
                request = this.auth.signUpWithFB(data.payload);
            } else {
                request = this.auth.signUpWithEmailAndPassword(data.payload);
            }
            return request.pipe(
                map(() => {
                    if (data.payload.dealership) {
                        this.storeNotify.dispatch(new SnackNotify('Thank you for your registration! Please check your email!'));
                        this.router.navigate(['/auth/welcome']);
                    } else {
                        this.storeAuth.dispatch(new actions.SignupSuccessAction(data.payload));
                    }
                }),
                catchError(err => of(this.dispatchError(err)))
            )
        })
    );

    @Effect({ dispatch: false })
    loginRedirect$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.LoginRedirect),
        tap((data: any) => {
            this.redirectUrl = data.payload || '';
            this.router.navigate([this.loginUrl]);
        })
    );

    @Effect({ dispatch: false })
    getTokenById$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.GetTokenByIdAction),
        switchMap((data: any) => this.auth.getOneHourToken(data.payload).pipe( 
            catchError(err => of(this.dispatchError(err)))
        )),
    );

    @Effect({ dispatch: false })
    authRedirect$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.AuthTokenPayload),
//        filter(_ => this.router.url === this.loginUrl),
        tap((data: any) => {
            this.wsService.login();
            this.router.navigate([this.redirectUrl]);
        })
    );

    @Effect()
    authUser$ = this.actions$.pipe(
        ofType(actions.AuthActionTypes.AuthUserChange),
        // switchMap((data: any) => data.payload.getIdToken()),
        tap((_: any) => (this.authToken.token = _.payload)),
        map((_: any) => this.authToken.readPayload(_.payload)),

        map(_ => new actions.AuthTokenPayload(_))
    );

    constructor(
        private actions$: Actions,
        private storeAuth: Store<AuthState>,
        private storeNotify: Store<NotifyState>,
        private authToken: AuthTokenService,
        private auth: AuthService,
        private bodyService: BodyService,
        private router: Router,
        private storage: StorageService,
        private wsService: WebsocketService,
    ) {
        // auth.onAuthStateChanged(data => {
        //   // console.log('\n\n onAuthStateChanged', data);
        // });

        // auth.onIdTokenChanged(authUser => {
        //   // console.log('\n\n onIdTokenChanged', data);
        //   if (authUser) {
        //     this.store.dispatch(new actions.AuthUserChange(authUser));
        //   } else {
        //     this.authToken.token = null;
        //     this.store.dispatch(new actions.NullToken());
        //   }
        // });
    }
}