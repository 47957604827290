import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PasswordValidator } from '@app/shared/validators/password.validator';
import { MatchValidator } from '@app/shared/validators/match.validator';
import { Store } from '@ngrx/store';
import { NotifyState } from '@app/core/store/notify/notify.reducer';
import { SnackNotify } from '@app/core/store/notify/notify.actions';
import { JsonApiService } from '@app/core/services/json-api.service';
import { UserService } from '@app/core/services/user.service';
import { BodyService } from '@app/core/services/body.service';
import { AppGlobals } from '@app/app.globals';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators'

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnDestroy {
    display: boolean = false;
    currentUser: any = {};
    profileForm: any = {
        form: FormGroup,
        validationMessages: {}
    }
    notifications: any[] = [
        { label: 'New inspection opportunities', sms: false, email: true },
        { label: 'When seller is 5 minutes away', sms: true, email: false }
    ]
    destroyed = new Subject<any>();
    
    constructor(
        private fb: FormBuilder,
        private router: Router,
        private userService: UserService,
        private bodyService: BodyService,
        private jsonApiService: JsonApiService,
        private store: Store<NotifyState>,
        public _globals: AppGlobals
    ) {
        this.profileForm.validationMessages = {
            firstName: {
                required: 'First Name is required.',
                maxlength: 'First Name is too long.'
            },
            lastName: {
                required: 'Last Name is required.',
                maxlength: 'Last Name is too long.'
            },
            phone: {
                required: 'Phone is required.',
                minlength: "Please enter a VALID phone number"
            },
            email: {
                required: 'Email is required.',
                email: 'Please enter a VALID email address',
                maxlength: 'Email is too long.'
            },
            password: {
                strong: "Password must be at least 6 characters long and must include: <ul><li>one upper letter</li><li>one lowercase letter</li><li>one number</li><li>one of the following special characters !@#$%^&*_-</li></ul>"
            },
            confirmPassword: {
                required: 'Confirm Password is required.',
                doesNotMatch: 'Does not match password.'
            }
        };
        this.initForm();
        this.userService.user$.subscribe(user => {
            this.currentUser = user;
            if (this.currentUser.id) {
                this.profileForm.form.patchValue({
                    firstName: this.currentUser.person.firstName,
                    lastName: this.currentUser.person.lastName,
                    phone: this.currentUser.person.phone,
                    email: this.currentUser.email,
                    dealerPhoto: this.currentUser.photoImagePath || ''
                });
            } else {
                this.hide();
            }
        });
        this.router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationEnd),
            takeUntil(this.destroyed)
        ).subscribe(() => {
            this.hide();
        });
    }
    
    initForm() {
        this.profileForm.form = this.fb.group({
            firstName: ['', [Validators.required, Validators.maxLength(40)]],
            lastName: ['', [Validators.required, Validators.maxLength(80)]],
            phone: ['', [Validators.required, Validators.minLength(this._globals.phoneLength)]],
            email: ['', [Validators.required, Validators.maxLength(320), Validators.email]],
            dealerPhoto: [''],
            password: ['', [PasswordValidator()]],
            confirmPassword: [''],
        });
        this.profileForm.form.get('password').valueChanges.subscribe(password => {
            if (password !== '') {
                this.profileForm.form.get('confirmPassword').setValidators([Validators.required, MatchValidator(this.profileForm.form.get('password'))]);
            } else {
                this.profileForm.form.get('confirmPassword').setValidators([MatchValidator(this.profileForm.form.get('password'))]);               
            }
            this.profileForm.form.get('confirmPassword').updateValueAndValidity();
        });
    }
    
    isDealerPhoto() {
        return typeof this.profileForm.form.get('dealerPhoto').value === 'string' && this.profileForm.form.get('dealerPhoto').value !== '';
    }
    
    getDealerPhotoSrc() {
        let path = this.profileForm.form.get('dealerPhoto').value.split('/');
        return environment.smartadmin.api + '/file/' + path[path.length - 1];
    }
    
    saveProfile(event: any) {
        event.preventDefault();
        if (this.profileForm.form.valid) {
            const profileFormValues = this.profileForm.form.getRawValue();
            const params = {
                password: null,
                user: {
                    role: this.currentUser.role,
                    person: this.currentUser.person,
                    meta: this.currentUser.meta,
                    photoImage: this.currentUser.photoImage || null,
                    email: profileFormValues.email
                }
            };
            params.user.person.firstName = profileFormValues.firstName;
            params.user.person.lastName = profileFormValues.lastName;
            params.user.person.phone = this._globals.userLang == 'ru' ? '+' + profileFormValues.phone.replace(/\+/g, '') : profileFormValues.phone;
            if (profileFormValues.password !== '') {
                params.password = profileFormValues.password;
            }
            let dealerPhoto = new Promise((resolve, reject) => {
                if (!profileFormValues.dealerPhoto || typeof profileFormValues.dealerPhoto === 'string') {
                    resolve();
                } else {
                    const maxWidth = 800;
                    const maxHeight = 800;
                    const reader = new FileReader();
                    const image = new Image();
                    const canvas = document.createElement('canvas');
                    const resize = () => {
                        let width = image.width;
                        let height = image.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    }
                    image.onload = () => {
                        resize();
                        params['user']['photoImage'] = {
                            name: profileFormValues.dealerPhoto.name,
                            type: profileFormValues.dealerPhoto.type,
                            body: canvas.toDataURL(profileFormValues.dealerPhoto.type).split(',')[1]
                        };
                        canvas.remove();
                        resolve();
                    }
                    reader.onload = (readerEvent) => image.src = readerEvent.target['result'];
                    reader.readAsDataURL(profileFormValues.dealerPhoto);
                }
            });
            dealerPhoto.then(() => {
                this.jsonApiService.put('user/' + this.currentUser.id, params).subscribe(() => {
                    this.userService.fetchUser().subscribe(() => {
                        this.store.dispatch(new SnackNotify('Profile was updated!'));
                    });
                });
            });            
        }
    }
    
    setNotification(name: string, index: number) {
        this.notifications[index][name] = !this.notifications[index][name];
    }
    
    isNotification(name: string, index: number) {
        return this.notifications[index][name];
    }
    
    saveNotifications(event: any) {
        event.preventDefault();
        const params = {
            password: null,
            user: {
                role: this.currentUser.role,
                person: this.currentUser.person,
                meta: this.currentUser.meta,
                photoImage: this.currentUser.photoImage || null,
                email: this.currentUser.email
            }
        };
        for (let notification of this.notifications) {
            let savedNotifIndex = params.user.meta.findIndex(item => item.name == notification.name);
            if (savedNotifIndex >= 0) {
                params.user.meta.splice(savedNotifIndex, 1);
            }
            if (notification.sms && notification.email) {
                continue;
            }
            params.user.meta.push({
                name: notification.name,
                value: notification.sms ? 'SMS' : (notification.email ? 'EMAIL' : 'NONE')
            });
        }
        this.jsonApiService.put('user/' + this.currentUser.id, params).subscribe(() => {
            this.userService.fetchUser().subscribe(() => {
                this.store.dispatch(new SnackNotify('Notifications were updated!'));
            });
        });
    }
    
    logoutAllConnections() {
        this.jsonApiService.put('session?invalidateOthers=true', {}).subscribe(() => {
            this.store.dispatch(new SnackNotify('Log out of all other connections'));
        });
    }
    
    show() {
        this.jsonApiService.fetch('user/uiSettings').subscribe(notifications => {
            this.notifications = notifications;
            this.notifications.map(notif => {
                notif['sms'] = true;
                notif['email'] = true;
                let savedNotif = this.currentUser.meta.find(item => item.name == notif.name);
                if (savedNotif && savedNotif.value !== '') {
                    notif['sms'] = !(savedNotif.value !== 'SMS');
                    notif['email'] = !(savedNotif.value !== 'EMAIL');
                }
                return notif;
            });
        });
        this.bodyService.addClass('settings-scr');
        this.display = true;
    }
    
    hide() {
        this.bodyService.removeClass('settings-scr');
        this.display = false;
    }
    
    onFileChange(event: any, control: FormControl) {
        control.patchValue('');
        event.target.parentNode.nextSibling.value = '';
        if (event.target.files && event.target.files.length > 0) {
            control.patchValue(event.target.files[0]);
            const parsed = event.target.value.split(/\\/);
            event.target.parentNode.nextSibling.value = parsed[parsed.length - 1];
        }
        control.markAsDirty();
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
        this.hide;
    }
}