import { Directive, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import "bootstrap-timepicker/js/bootstrap-timepicker.min.js";

@Directive({
    selector: '[smartTimepicker]'
})
export class SmartTimepickerDirective implements OnInit {
    @Input() smartTimepicker: any;
    @Output() timepickerChange = new EventEmitter<any>();

    constructor(private el: ElementRef) { }

    ngOnInit() {
        let this_ = this;
        $(this.el.nativeElement).timepicker(this.smartTimepicker || {}).on('changeTime.timepicker', function(e) {
            this_.timepickerChange.emit(e.time.value);
        });
        if (this.smartTimepicker.defaultTime) {
            this_.timepickerChange.emit(this.smartTimepicker.defaultTime);            
        }
        $(this.el.nativeElement).keydown(e => {
            if (e.key == "Tab") {
                $(this_.el.nativeElement).timepicker("hideWidget");
            }
        });
    }
}