import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Store } from '@ngrx/store';
import { SnackNotify } from '@app/core/store/notify/notify.actions';
import { LogoutAction } from '@app/core/store/auth/auth.actions';
import { AuthState } from '@app/core/store/auth/auth.reducer';
import { NotificationService } from "@app/core/services/notification.service";
import { UserService } from '@app/core/services/user.service';
import { StorageService } from '@app/core/services/storage.service';
import { JsonApiService } from "@app/core/services/json-api.service";
import { SettingsComponent } from '@app/shared/components/settings/settings.component';

declare var $: any;

@Component({
    selector: 'sa-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent {
    @ViewChild('changeDealershipModal') changeDealershipModal: TemplateRef<any>;
    @ViewChild('settings') settings: SettingsComponent;
    bsModalRef: BsModalRef;
    searchMobileActive: boolean = false;
    currentUser: any = {};
    selectedDealership: string = '';

    constructor(
        private router: Router,
        private store: Store<AuthState>,
        private notificationService: NotificationService,
        private userService: UserService,
        private jsonApiService: JsonApiService,
        private storage: StorageService,
        private modalService: BsModalService
    ) {
        this.userService.user$.subscribe(user => {
            this.currentUser = user
            if (this.isAllowChangeDealership()) {
                this.selectedDealership = this.currentUser.dealership.id+'|'+this.currentUser.dealership.name;
                this.storage.get('JUST_LOGGED_IN').then(value => {
                    if (value) {
                        this.openModal(null, this.changeDealershipModal);
                        this.storage.remove('JUST_LOGGED_IN');
                    }
                });
            }
        });
    }
    
    isAllowChangeDealership() {
        return this.currentUser.role === 'DEALER_ADMIN' && this.currentUser.dealership && this.currentUser.dealership.group;
    }
    
    isShowDealership() {
        return this.currentUser.dealership;
    }
 
    clickLogo() {
        this.settings.hide();
        this.closeModal();
    }

    selectDealership() {
        let dealershipId = this.selectedDealership.split('|')[0];
        if (dealershipId !== this.currentUser.dealershipId) {
            this.jsonApiService.put('user/dealership/' + dealershipId, {}).subscribe(user => {
                this.userService.fetchUser().subscribe(() => {
                    this.store.dispatch(new SnackNotify('Dealership was changed!'));
                    this.closeModal();
                });
            });
        }
    }
       
    toggleSearchMobile() {
        this.searchMobileActive = !this.searchMobileActive;

        $('body').toggleClass('search-mobile', this.searchMobileActive);
    }
    
    showSettings() {
        this.settings.show();
    }

    onSubmit() {
        this.router.navigate(['/miscellaneous/search']);
    }

    logout() {
        this.notificationService.smartMessageBox(
            {
                title: "<i class='fas fa-sign-out-alt txt-color-orangeDark'></i> Logout ?",
                content: "Are you sure want to logout ?",
                buttons: "[Yes][No]"
            },
            buttonPressed => {
                if (buttonPressed == "Yes") {
                    this.store.dispatch(new LogoutAction());
                }
            }
        );
    }
    
    openModal(event: any, template: TemplateRef<any>) {
        if (event) {
            event.preventDefault();
        }
        if (this.modalService.getModalsCount() === 0) {
            this.bsModalRef = this.modalService.show(template);
        }
    }
    
    closeModal() {
        if (this.bsModalRef) {
            this.bsModalRef.hide();
        }
    }
}