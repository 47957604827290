import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AuthState, LogoutAction, getLoggedIn } from '../store/auth';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, take } from 'rxjs/operators';
import { AuthTokenService } from './auth-token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        public authToken: AuthTokenService,
        public store: Store<AuthState>
    ) {}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authToken.token && (request.url.search('jwt/') < 0 || request.url.search('impersonate') > 0)) {
            let headers:any = { setHeaders: { authorization: this.authToken.token } };
            if (typeof(request.body) === 'string') {
                headers.setHeaders['content-type'] = 'application/json;charset=UTF-8';
            }
            request = request.clone(headers);
            return next.handle(request).pipe(
                catchError((error, caught) => {
                    this.store.select(getLoggedIn).pipe(
                        take(1)
                    ).subscribe(authed => {
                        if (error.status === 401) {
                            if (authed) {
                                this.store.dispatch(new LogoutAction());
                            } else {
                                this.store.dispatch(new LogoutAction({ withoutRequest: true }));
                            }
                        }
                    });
                    return throwError(error);
                })
            );
        } else {
            return next.handle(request).pipe(
                tap((ev: HttpEvent<any>) => {
                    if (ev instanceof HttpResponse) {
                        if (request.url.search('jwt/') >= 0) {
                            this.authToken.token = ev.body.token;
                        } else if (request.url.search('session/one-hour-token/') >= 0) {
                            this.authToken.token = ev.body.jwt;
                        }
                    }
                })
            );
        }
    }
}