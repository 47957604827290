import { Injectable } from '@angular/core';

declare var $: any;

@Injectable()
export class NotificationService {
    constructor() {}

    smallBox(data, cb?) {
        $.smallBox(data, cb);
        let xDown, yDown, xUp, yUp;
        $("#divSmallBoxes > .SmallBox")
            .on('mousedown touchstart', function(e) {
                if (e.touches && e.touches.length > 0) {
                    xDown = e.touches.item(0).pageX;
                    yDown = e.touches.item(0).pageY;
                }
            })
            .on('mouseup touchend',function(e) {
                if (e.changedTouches && e.changedTouches.length > 0) {
                    xUp = e.changedTouches.item(0).pageX;
                    yUp = e.changedTouches.item(0).pageY;
                    if (xDown != xUp || yDown != yUp) {
                        $(e.currentTarget).removeClass("fadeIn fast");
                        $(e.currentTarget).addClass("fadeOut fast").delay(300).queue(function(){$(this).clearQueue(),$(this).remove()});
                    }
                }
            });    
    }

    bigBox(data, cb?) {
        $.bigBox(data, cb)
    }

    smartMessageBox(data, cb?) {
        $.SmartMessageBox(data, cb)
        $('.divMessageBox button:first').focus();
    }
}