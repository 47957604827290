import { Injectable } from '@angular/core';
import { HttpEventType, HttpEvent, HttpRequest, HttpClient } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { delay, map, catchError, tap, last } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ShowError } from '../store/notify/notify.actions';
import { NotifyState } from '../store/notify/notify.reducer';
import { environment } from '@env/environment';

@Injectable()
export class JsonApiService {
    constructor(
        private http: HttpClient,
        private store: Store<NotifyState>
    ) {}

    public fetch(url: string, options?: any): Observable<any> {
        return this.http.get(this.getBaseUrl(url), options)
            .pipe(
                delay(100),
                map((data: any) => (data && data.data ? data.data : data)),
                catchError((err: any) => { return options && options.notShowErrors ? throwError(err) : this.handleError(err); })
            )
    }
    
    public put(url: string, data: any, silent: boolean = false): Observable<any> {
        let request = silent ? this.http.put(this.getBaseUrl(url), data, { params: { silent: 'true' } }) : this.http.put(this.getBaseUrl(url), data);
        return request
            .pipe(
                delay(100),
                catchError(this.handleError)
            )
    }
    
    public post(url: string, data: any, silent: boolean = false): Observable<any> {
        let request = silent ? this.http.post(this.getBaseUrl(url), data, { params: { silent: 'true' } }) : this.http.post(this.getBaseUrl(url), data);
        return request
            .pipe(
                delay(100),
                catchError(this.handleError)
            )
    }
    
    public delete(url: string): Observable<any> {
        return this.http.delete(this.getBaseUrl(url))
            .pipe(
                delay(100),
                catchError(this.handleError)
            )
    }
    
    public upload(url: string, file: any, body: any, progress: Function): Observable<any> {
        const req = new HttpRequest('POST', this.getBaseUrl(url), body, {
            reportProgress: true
        });
        return this.http.request(req).pipe(
            map((event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.Sent:
                        return { msg: 'Uploading file ' + file.name + ' of size ' + file.size + '.' };
                    case HttpEventType.UploadProgress:
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        return { msg: 'File ' + file.name + ' is ' + percentDone + '% uploaded.', progress: percentDone };
                    case HttpEventType.Response:
                        return { msg: 'File ' + file.name + ' was completely uploaded!', success: true, result: event.body };
                    default:
                        return { msg: 'File ' + file.name + ' surprising upload event: ' + event.type + '.' };
                }
            }),
            tap(result => progress(result)),
            last(), // return last (completed) message to caller
            catchError(this.handleError)
        );
    }

    public getBaseUrl(url: string = '') {
        return environment.smartadmin.api + '/' + url;
    }

    handleError = (response: any) => {
        let err = {
            code: response.error && response.error.httpStatusCode ? response.error.httpStatusCode : response.status,
            message: response.error && response.error.message ? response.error.message : response.message
        }
        if (err.code == '500') {
            err.message = "We're sorry, something went wrong. Please try again later";
        }
        if (err.code != 401 || response.url.search('jwt/') >= 0) {
            this.store.dispatch(new ShowError(err));
        }
        return throwError(err);
    }
}