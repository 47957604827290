import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { LoginRedirect, LogoutAction, getLoggedIn, AuthState, GetTokenByIdAction } from '../store/auth';
import { UserService } from '@app/core/services/user.service';
import { AuthTokenService } from '@app/core/services/auth-token.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<AuthState>,
        private router: Router,
        private userService: UserService,
        private authTokenService: AuthTokenService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            select(getLoggedIn),
            map(authed => {
                if (authed) {
                    const user = this.userService.user$.value;
                    const role = user['role'] || '';
                    if (role !== '' && 
                        ((state.url.search(/^\/seller/) < 0 && role == 'SELLER') ||
                         (state.url.search(/^\/dealer/) < 0 && (['DEALER', 'DEALER_ADMIN'].indexOf(role) >= 0)) ||
                         (state.url.search(/^\/admin/) < 0 && role == 'ADMIN') ||
                         state.url.search(/^\/auth/) >= 0)
                    ) {
                        const path = role === 'DEALER_ADMIN' ? 'dealer' : role.toLowerCase();
                        this.router.navigate(['/'+path]);
                        return false;
                    }
                    if (role !== '' && ['SELLER', 'DEALER', 'DEALER_ADMIN', 'ADMIN'].indexOf(role) < 0) {
                        this.store.dispatch(new LogoutAction());
                        return false;
                    }
                    return true;
                } else if (state.url.search(/^\/auth/) < 0) {
                    const tokenId = route.queryParams['tokenId'];
                    let url = state.url;
                    if (tokenId && tokenId !== '') {
                        url = url.split('?')[0];
                        if (typeof this.authTokenService.token$.value == 'undefined' || !this.authTokenService.token$.value) {
                            this.store.dispatch(new GetTokenByIdAction(tokenId));
                        }
                    }
                    this.store.dispatch(new LoginRedirect(url));
                    return false;
                }
                return true;
            }),
            take(1)
        );
    }
}
