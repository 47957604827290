import { Component, Renderer2 } from "@angular/core";
import { routerTransition } from "@app/shared/utils/animations";
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: "app-main-layout",
    templateUrl: "./main-layout.component.html",
    styles: [],
    animations: [routerTransition]
})
export class MainLayoutComponent {
    previousUrl: string;
    
    constructor(
        private renderer: Renderer2,
        private router: Router
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    if (this.previousUrl) {
                        this.renderer.removeClass(document.body, this.previousUrl);
                    }
                    let currentUrlSlug = event.url.slice(1).replace(/\//g, "-");
                    if (currentUrlSlug) {
                        this.renderer.addClass(document.body, currentUrlSlug);
                    }
                    this.previousUrl = currentUrlSlug;
                }
            });        
    }

    getState(outlet) {
        if (!outlet.activated) return;
        let ss = outlet.activatedRoute.snapshot;

        // return unique string that is used as state identifier in router animation
        return (
            outlet.activatedRouteData.state ||
            (ss.url.length
                ? ss.url[0].path
                : ss.parent.url.length
                    ? ss.parent.url[0].path
                    : null)
        );
    }
}
