import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarzazChatComponent } from "./carzaz-chat.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [CarzazChatComponent],
    exports: [CarzazChatComponent]
})
export class CarzazChatModule { }
