import { Component, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, TemplateRef, HostListener } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators'
import { UserService } from '@app/core/services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { JsonApiService } from "@app/core/services/json-api.service";
import { Subscription } from 'rxjs-compat/Subscription';
import { WebsocketService } from '@app/shared/websocket/websocket.service';
import { NotificationService } from "@app/core/services/notification.service";
import { environment } from '@env/environment';

enum ViewModes {
    Threads,
    Messages
}

@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnDestroy {
    @ViewChild('popover') popover: any;
    @HostListener('ShowPopover', ['$event.detail']) showPopover(data: any) {
        let receiverId = data.dealership ? data.dealership.dealershipInfo.dealerId : data.vehicle.sellerId;
        this.jsonApiService.fetch('vehicle/'+data.vehicle.id+'/threads/'+receiverId).subscribe(thread => {
            if (!thread.vehicle && data.vehicle) {
                thread.vehicle = data.vehicle;
            }
            if (data.dealership) {
                thread.dealership = data.dealership;
            }
            this.openThread(thread);
        });
    }
    bsModalRef: BsModalRef;
    ViewModesEnum = ViewModes;
    currentUser: any = {};
    threads: any[] = [];
    limitThreads: number = 10;
    offsetThreads: number = 0;
    totalThreads: number = -1;
    messages: any[] = [];
    limitMessages: number = 10;
    offsetMessages: number = 0;
    totalMessages: number = -1;
    isLoaded: boolean = false;
    unreadMessagesCount: number = 0;
    currentMessage: string = '';
    currentThreadInfo: any = {};
    viewMode: ViewModes = ViewModes.Threads;
    sending: boolean = false;
    private textMessages: Subscription;
    uploadProgress: string;
    attachment: any = null;
    attachmentImageSrc: string = '';
    destroyed = new Subject<any>();
    
    constructor(
        private jsonApiService: JsonApiService,
        private userService: UserService,
        private wsService: WebsocketService,
        private el: ElementRef,
        private router: Router,
        private notificationService: NotificationService,
        private modalService: BsModalService
    ) {
        this.userService.user$.subscribe(user => {
            this.currentUser = user;
            this.viewMode = ViewModes.Threads;
            this.reloadThreads();
        });
        this.textMessages = this.wsService.on('info-notification').subscribe(data => {
            if (data.params.find((param: any) => param.key == 'obj' && param.value == 'VEHICLE_PHOTO')) {
                this.threads.map(thread => {
                    if (thread.vehicle.id == data.payload.id) {
                        thread.vehicle.photos = data.payload.photos;
                        if (this.currentThreadInfo.thread.id == thread.id) {
                            this.currentThreadInfo.thread = thread;
                            this.currentThreadInfo.avatar = this.opponent(thread, 'image');
                        }
                    }
                });
            }
            if (data.params.find((param: any) => param.key == 'obj' && param.value == 'MESSAGE')) {
                if (this.viewMode == this.ViewModesEnum.Messages && this.currentThreadInfo.thread.id && this.currentThreadInfo.thread.id == data.payload.rootMessageId) {
                    this.reloadMessages();
                } else {
                    this.reloadThreads();
                }
            }
        });
        this.router.events.pipe(
            filter((event: RouterEvent) => event instanceof NavigationEnd),
            takeUntil(this.destroyed)
        ).subscribe(event => {
            if (event.url.match(/\/\d+\/messages/)) {
                let threadId = event.url.match(/\/(\d+)\/messages/)[1];
                this.jsonApiService.fetch('thread/'+threadId).subscribe(thread => {
                    this.openThread(thread);
                });
            }
        });
    }

    get chatEnded() {
        return this.currentThreadInfo.thread && this.currentThreadInfo.thread.closed;
    }

    openThread(thread: any) {
        let searchThread = this.threads.filter(t => t.id == thread.id);
        if (searchThread.length > 0) {
            thread = searchThread[0];
        }
        if (!(thread.id && this.currentThreadInfo.thread && this.currentThreadInfo.thread.id && this.currentThreadInfo.thread.id == thread.id)) {
            this.messages = [];
        }
        this.currentThreadInfo = {
            avatar: this.opponent(thread, 'image'),
            title: this.opponent(thread, 'name'),
            description: '',
            receiverId: this.opponent(thread, 'userId'),
            vehicleId: thread.vehicle.id,
            thread: thread
        }
        this.viewMode = this.ViewModesEnum.Messages;
        if (!this.popover.isOpen) {
            this.popover.show();
        }
        this.el.nativeElement.querySelector('textarea').focus();
        this.el.nativeElement.querySelector('textarea').value = '';
        if (this.chatEnded) {
            this.el.nativeElement.querySelector('textarea').value = 'This Conversation has ended.';
        }
        this.clearAttachment();
        this.reloadMessages();
    }

    backThreads() {
        this.viewMode = this.ViewModesEnum.Threads;
    }

    opponent(thread: any, field: string) {
        let opponent = thread.participants && thread.participants.filter(user => user.userId !== this.currentUser.id)[0] || false;
        if (!opponent) {
            if (thread.dealership) {
                opponent = {
                    role: 'DEALER',
                    name: thread.dealership.dealershipInfo.dealerName,
                    userId: thread.dealership.dealershipInfo.dealerId
                };
            } else {
                opponent = {
                    role: 'SELLER',
                    name: '',
                    userId: thread.vehicle.sellerId
                };
            }
        }
        if (field == 'name') {
            if (opponent['role'] == 'SELLER') {
                return (opponent['name'] == '' ? '' : opponent['name']+', ')+[thread.vehicle.year, thread.vehicle.make, thread.vehicle.model, thread.vehicle.trim].join(' ');
            } else if (opponent['dealershipName']) {
                return [opponent['name'], opponent['dealershipName']].join(', ');
            }
        }
        if (field == 'image') {
            if (opponent['photoImagePath']) {
                let path = opponent['photoImagePath'].split('/');
                return environment.smartadmin.api + '/file/' + path[path.length - 1];
            }
            if (opponent['role'] == 'SELLER' && thread.vehicle && thread.vehicle.photos.length > 0) {
                return environment.smartadmin.api + '/file/' + thread.vehicle.photos[0].thumbnailFileName;
            }

            return '';
            // return 'assets/img/carzaz/carzaz.png';
        }

        return opponent[field] || '';
    }

    getPhoto(url: string, name: string, thread: any = null) {
        const unreadMsgCount = thread && thread.unreadMessagesCount > 0 ? '<em class="flash animated msgcounter">' + thread.unreadMessagesCount + '</em>' : '';
        if (url !== '') {
            return '<img src="'+url+'"/>'+unreadMsgCount;
        }
        let chars = 'DN';
        let parseName = name.split(' ');
        if (parseName.length > 0) {
            chars = parseName[0][0] + (parseName[1] ? parseName[1][0] : '');
        }
        
        return '<div class="chars">'+chars+'</div>'+unreadMsgCount;
    }

    recalcUnreadMessages() {
        this.unreadMessagesCount = 0;
        this.threads.map(thread => {
            this.unreadMessagesCount += thread.unreadMessagesCount;
        });
    }

    reloadThreads(){
        this.offsetThreads = 0;
        this.totalThreads = -1;
        this.getThreads();
    }
    
    getThreads() {
        if (this.totalThreads < 0 || this.offsetThreads < this.totalThreads) {
            this.isLoaded = false;
            let params = [];
            params.push('limit=' + this.limitThreads);
            params.push('offset=' + this.offsetThreads);
            let offset = this.offsetThreads;
            this.jsonApiService.fetch('threads?'+params.join('&')).subscribe((threads: any) => {
                if (offset == 0) {
                    this.threads = [];
                }
                this.threads = this.threads.concat([ ...threads.list ]);
                this.recalcUnreadMessages();
                this.totalThreads = threads.total;
                this.offsetThreads += this.limitThreads;
                this.isLoaded = true;
            }, () => this.isLoaded = true);
        }
    }

    reloadMessages(){
        this.offsetMessages = 0;
        this.totalMessages = 0;
        if (this.currentThreadInfo.thread.totalMessagesCount > 0) {
            this.totalMessages = -1;
            this.getMessages();
        } else {
            this.messages = [];        
        }
    }

    getMessages() {
        if (this.totalMessages < 0 || this.offsetMessages < this.totalMessages) {
            this.isLoaded = false;
            let params = [];
            params.push('limit=' + this.limitMessages);
            params.push('offset=' + this.offsetMessages);
            let offset = this.offsetMessages;
            this.jsonApiService.fetch('threads/'+this.currentThreadInfo.thread.id+'/messages?'+params.join('&')).subscribe((messages: any) => {
                if (offset == 0) {
                    this.messages = [];
                }
                let ids = [];
                for (let message of messages.list) {
                    if (!message.readAt && message.sender.userId !== this.currentUser.id) {
                        ids.push(message.id);
                        message.readAt = true;
                    }
                }
                if (ids.length > 0) {
                    this.jsonApiService.post('messages/markread', ids, true).subscribe(() => {
                        this.currentThreadInfo.thread.unreadMessagesCount -= ids.length;
                        this.recalcUnreadMessages();
                    });
                }
                let currentScrollHeight = offset == 0 ? 0 : this.el.nativeElement.querySelector('.messages-items').scrollHeight;
                this.messages = messages.list.reverse().concat([ ...this.messages ]);
                this.totalMessages = messages.total;
                this.offsetMessages += this.limitMessages;
                setTimeout(() => this.el.nativeElement.querySelector('.messages-items').scrollTop = this.el.nativeElement.querySelector('.messages-items').scrollHeight - currentScrollHeight, 0);
                this.isLoaded = true;
            }, () => this.isLoaded = true);
        }
    }

    sendMessage() {
        const iMessage = {
            images: [],
            receiverId: this.currentThreadInfo.receiverId,
            subject: '',
            vehicleId: this.currentThreadInfo.vehicleId,
            body: this.currentMessage
        }
        this.sending = true;
        this.uploadAttachment().then(iAttachment => {
            if (iAttachment) {
                iMessage['images'].push(iAttachment);
            }
            this.jsonApiService.post('messages', iMessage).subscribe(oMessage => {
                this.reloadThreads();
                if (!this.currentThreadInfo.thread.id) {
                    this.jsonApiService.fetch('vehicle/'+oMessage.vehicleId+'/threads/'+oMessage.receiver.userId).subscribe(thread => this.openThread(thread));
                } else {
                    this.reloadMessages();
                }
                this.currentMessage = '';
                this.clearAttachment();
                this.sending = false
            }, () => this.sending = false);
        });
    }

    uploadAttachment(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.attachment) {
                resolve(null);
            }
            const reader = new FileReader();
            reader.onload = readerEvent => { 
                resolve({
                    name: this.attachment.formData.get('file').name,
                    type: this.attachment.formData.get('file').type,
                    body: readerEvent.target['result'].split(",")[1]
                });
            };
            reader.readAsDataURL(this.attachment.formData.get('file'));
        });
    }

    getAttachmentSrc(message: any, thumb: boolean = true) {
        return environment.smartadmin.api + '/file/' + (thumb ? message.images[0].thumbnailFileName : message.images[0].fileName);
    }

    clearAttachment() {
        this.attachment = null;
        if (this.el.nativeElement.querySelector('.input-file input')) {
            this.el.nativeElement.querySelector('.input-file input').parentNode.nextSibling.value = '';
        }
    }

    mediaActions(event: any) {
        if (event.action == 'changed') {
            this.attachment = event.args;
        }
    }

    onMute() {
        this.notificationService.smartMessageBox({
            title : "<i class='fas fa-exclamation txt-color-orangeDark'></i> Mute",
            content : "This conversation will be muted until you turn it back on. You will not be notified of the messages from that particular user for that particular vehicle.",
            buttons : '[Cancel][Mute]'
        }, buttonPressed => {
            if (buttonPressed == 'Mute') {
                this.muteToggle(true);
            }
        });
    }

    isShowMuteControl(control: string) {
        return this.viewMode == this.ViewModesEnum.Messages && this.currentThreadInfo.thread.id && (control == 'mute' ? !this.currentThreadInfo.thread.muted : this.currentThreadInfo.thread.muted);
    }

    muteToggle(status: boolean) {
        this.jsonApiService.post('threads/'+this.currentThreadInfo.thread.id+'/'+(status ? 'mute' : 'unmute'), {}).subscribe(() => {
            this.currentThreadInfo.thread.muted = status;
        });
    }

    onHidden() {
        this.viewMode = this.ViewModesEnum.Threads;
    }
       
    onScroll() {
        if (this.isLoaded) {
            if (this.viewMode == this.ViewModesEnum.Threads) {
                this.getThreads();
            } else if (this.viewMode == this.ViewModesEnum.Messages) {
                this.getMessages();
            }
        }
    }
    
    openModal(event: any, template: TemplateRef<any>, message: any) {
        event.preventDefault();
        this.attachmentImageSrc = this.getAttachmentSrc(message, false);
        this.bsModalRef = this.modalService.show(template);
    }

    ngOnDestroy() {
        this.textMessages.unsubscribe();
        this.destroyed.next();
        this.destroyed.complete();
    }
}