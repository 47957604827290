import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";

const routes: Routes = [
    {
        path: "",
        component: MainLayoutComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: "admin",
        component: MainLayoutComponent,
        canActivate: [ AuthGuard ],
        loadChildren: "./features/admin/admin.module#AdminModule"
    },
    {
        path: "seller",
        component: MainLayoutComponent,
        canActivate: [ AuthGuard ],
        loadChildren: "./features/seller/seller.module#SellerModule"
    },
    {
        path: "dealer",
        component: MainLayoutComponent,
        canActivate: [ AuthGuard ],
        loadChildren: "./features/dealer/dealer.module#DealerModule"
    },
    {
        path: "auth",
        component: AuthLayoutComponent,
        canActivate: [ AuthGuard ],
        loadChildren: "./features/auth/auth.module#AuthModule"
    },
    { path: "**", redirectTo: "miscellaneous/error404" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
