import { Directive, Input, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: "[restriction]"
})
export class Restriction {
    @Input() restriction: string;

    constructor(private el: ElementRef) { }

    @HostListener('keyup', ['$event']) onKeyUp() {
        let re = new RegExp(this.restriction, "gi");
        this.el.nativeElement.value = this.el.nativeElement.value.replace(re, '');
    }
}