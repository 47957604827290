// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=demo` then `environment.demo.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    firebase: {},
    debug: true,
    log: {
        auth: true,
        store: true,
    },
    smartadmin: {
        api: 'https://demo.carzaz.com/api',
        ws: 'wss://demo.carzaz.com/ws',
        db: 'carzaz-angular',
        paypal: {
            client: {
                sandbox: 'ARYE4dDnvG8AE_M5dcd8xl84Wz9l-HNIWNofB1Ypa1JsIRBXafG9bYi_LYT-9njQrhqUSD9gVPYuheaR',
                production: '<insert production client id>'
            }
        },
        facebook: {
            appId: '184993875737319'
        },
        ipstack: {
            apikey: ''
        }
    },
};
