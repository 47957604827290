import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { switchMap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Store } from '@ngrx/store';
import { AuthTokenPayload, AuthInit } from '../store/auth/auth.actions';
import { AuthState } from '../store/auth/auth.reducer';
import { UserService } from '@app/core/services/user.service';

const USER_TOKEN = 'token';

@Injectable()
export class AuthTokenService {
    public token$ = new BehaviorSubject(null);

    constructor(
        private storage: StorageService,
        private store: Store<AuthState>,
        private userService: UserService
    ) { }

    load(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.store.dispatch(new AuthInit());

            this.storage.get(USER_TOKEN).then(
                token => {
                    this.storage.get('remember').then(remember => {
                        if (!remember && !sessionStorage.getItem('identitySession')) {
                            token = null;
                        }
                        environment.log.auth && console.log((!!token ? "logged" : "not logged") + " at boot");
                        this.token = token;
                        this.token$
                            .pipe(
                                switchMap(this.renewToken),
                                switchMap(this.dumpToken)
                            )
                            .subscribe(() => {
                                if (!!this.token) {
                                    this.userService.fetchUser().subscribe(() => {
                                        this.store.dispatch(new AuthTokenPayload(this.readPayload(this.token)));
                                    })
                                } else {
                                    this.userService.defaultUser();
                                }
                            });
                        resolve(token);
                    });
                },
                error => {
                    resolve(null);
                }
            );
        });
    }

    renewToken = token => {
        if (!!token) {
            try {
                let payload = this.readPayload(token);
                let now = Date.now();
                if (payload.exp * 1000 > now && payload.exp * 1000 - now < 1*60*60*1000) {
                    return this.userService.renewToken().toPromise().then(session => {
                        return Promise.resolve(session.token);
                    });
                }
            } catch (error) {
                token = null;
            }
        }
        return Promise.resolve(token);
    }

    dumpToken = token => {
        environment.log.auth && console.log("\n\n\n================\ndump auth token", token);
        return !!token
            ? this.storage.set(USER_TOKEN, token)
            : this.storage.remove(USER_TOKEN).then(() => null)
    };

    set token(value) {
        this.token$.next(value);
    }
    get token() {
        return this.token$.value;
    }

    readPayload(token) {
        let payload = this.getTokenPayload(token);
        return payload; // && payload.user ? Object.assign({roles: [], id: null},
        // {id: payload.user.id, roles: JSON.parse(payload.user.roles)}) : null
    }

    getTokenPayload(token) {
        return token
            ? JSON.parse(this.b64DecodeUnicode(token.split(".")[1]))
            : null;
    }

    b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function(c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    }
}

export function AuthTokenFactory(service: AuthTokenService): Function {
    return () => service.load();
}
