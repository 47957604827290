import { Injectable } from '@angular/core';
import * as models from './list.model';
import { JsonApiService } from '../json-api.service';

@Injectable()
export class ListService {
    constructor(
        private jsonApiService: JsonApiService
    ) {}
    
    getList(name: string, args?: any) {
        let list = new models[name](args);
        list.loading = new Promise(resolve => {
            if (list.api === '' || list.api.match(/\{/)) {
                resolve();
            } else {
                this.jsonApiService.fetch(list.api).subscribe(
                    data => {
                        list.fill(data);
                        resolve(list);
                    }
                );
            }
        });
        return list;
    }
}