import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';

if(!HTMLCanvasElement.prototype.toBlob){
    HTMLCanvasElement.prototype.toBlob = function(callback, type, encoderOptions){
        var dataurl = this.toDataURL(type, encoderOptions);
        var bstr = atob(dataurl.split(',')[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        var blob = new Blob([u8arr], {type: type});
        callback.call(this, blob);
    };
}

@Component({
    selector: 'file-upload',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./fileupload.component.scss'],
    template: `
        <label class="label">{{ label }}<span>{{ required ? ' *Required' : '' }}</span></label>
        <div id="{{ id }}" class="input input-file" *ngIf="!isFileUploaded()">
            <span class="button"><input type="file" [accept]="type == 'image' ? 'image/jpeg, image/png' : 'video/mp4'" (change)="onFileChange($event)">Browse</span><input type="text" placeholder="Attach a file" readonly>
            <div class="progress margin-top-5" *ngIf="progress < 100">
                <div class="progress-bar bg-color-blue" role="progressbar" [style.width.%]="progress">{{ progress }}%</div>
            </div>            
        </div>
        <div *ngIf="isFileUploaded()">
            <div class="uploaded-media" *ngIf="options.preview">
                <img src="{{ getMediaSrc() }}" *ngIf="type == 'image'"/>
                <div style="padding:56.25% 0 0 0; position:relative;" *ngIf="type == 'video'">
                    <video style="position:absolute;top:0;left:0;width:100%;height:100%;" src="{{ getMediaSrc() }}" controls></video>
                </div>
            </div>
            <div class="input">
                <a class="btn btn-default btn-primary" (click)="setActions('delete')">DELETE {{ type | uppercase }}</a>
            </div>
        </div>
    `
})

export class FileUploadComponent {
    @Input() id: string = '';
    @Input() type: string = 'image';
    @Input() label: string = '';
    @Input() required: boolean = false;
    @Input() progress: number = 100;
    @Input() uploadedFile: any;
    @Input() options: any = { preview: true };
    @Output() actions = new EventEmitter<any>();
    
    constructor() {}

    onFileChange(event: any) {
        let value = '';
        event.target.parentNode.nextSibling.value = '';
        if (event.target.files && event.target.files.length > 0) {
            value = event.target.files[0];
            event.target.parentNode.nextSibling.value = event.target.value;
        }
        this.uploadFile(value);
    }
    
    isFileUploaded() {
        return this.uploadedFile && ((this.uploadedFile.uploaded && this.type == 'image') || (this.uploadedFile.id && this.type == 'video'));
    }
    
    getMediaSrc() {
        if (this.type == 'image') {
            return this.uploadedFile && this.uploadedFile.uploaded ? environment.smartadmin.api + '/file/' + this.uploadedFile.fileName : '';
        }
        if (this.uploadedFile && this.uploadedFile.id) {
            let parsedPath = this.uploadedFile.value.split('/');
            return environment.smartadmin.api + '/file/' + parsedPath[parsedPath.length - 1];
        }
        return '';
    }
    
    setActions(action: string, args: any = {}) {
        this.actions.emit({ action: action, args: args });
    }
    
    uploadFile(file: any) {
        if (file === '') {
            this.setActions('changed', null);
            return;
        }
        if (this.type == 'image') {
            const maxWidth = 800;
            const maxHeight = 800;
            const reader = new FileReader();
            const image = new Image();
            const canvas = document.createElement('canvas');
            const resize = () => {
                let width = image.width;
                let height = image.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            }
            image.onload = () => {
                resize();
                canvas.toBlob((blob) => {
                    let formData = new FormData();
                    formData.append('file', blob, file['name']);
                    this.setActions('changed', { formData: formData, file: file });
                    canvas.remove();
                }, file.type);
            }
            reader.onload = (readerEvent) => image.src = readerEvent.target['result'];
            reader.readAsDataURL(file);
        } else {
            let formData = new FormData();
            formData.append('file', file, file['name']);
            this.setActions('changed', { formData: formData, file: file });
        }
    }
}