import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap } from 'rxjs/operators';
import { JsonApiService } from "@app/core/services/json-api.service";
import { BodyService } from "@app/core/services/body.service";

const defaultUser = {
    username: "Guest"
}
@Injectable()
export class UserService {
    private currentUser: any;
    public user$ = new BehaviorSubject({ ...defaultUser });

    constructor(
        private jsonApiService: JsonApiService,
        private bodyService: BodyService
    ) {}
    
    fetchUser(): Observable<any> {
        this.bodyService.removeClass("loaded");
        return this.jsonApiService.fetch("session").pipe(
            switchMap((user: any) => {
                this.currentUser = user
                this.currentUser['username'] = this.currentUser.person.firstName + ' ' + this.currentUser.person.lastName;
                if (user.role == 'DEALER' || user.role == 'DEALER_ADMIN') {
                    return this.jsonApiService.fetch('dealership/' + user.dealershipId);
                }
                return of(false);
            }),
            map(dealership => {
                if (dealership) {
                    this.currentUser['dealership'] = dealership;
                }
                this.user$.next(this.currentUser);
                this.bodyService.addClass("loaded");
            })
        );
    }
    
    renewToken(): Observable<any> {
        return this.jsonApiService.put("session?renew=true", {});
    }

    defaultUser() {
        this.user$.next({ ...defaultUser })
    }
}