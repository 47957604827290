import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonApiService } from './json-api.service';
import { StorageService } from './storage.service';

@Injectable()
export class AuthService {
    constructor(
        private jsonApiService: JsonApiService,
        private storageService: StorageService
    ) {}

    signInWithFB(params: any) {
        return this.jsonApiService.post('jwt/facebook', params);
    }

    signInWithEmailAndPassword(username: string, password: string, remember: boolean): Observable<any> {
        this.storageService.set('remember', remember).then(() => sessionStorage.setItem('identitySession', Date.now().toString()));
        return this.jsonApiService.post('jwt/password', { email: username, password: password });
    }

    signUpWithFB(params: any): Observable<any> {
        return this.jsonApiService.post('user/consumer/facebook', params);
    }
        
    signUpWithEmailAndPassword(params: any): Observable<any> {
        return params.dealership ? this.jsonApiService.post('dealershipWithAdmin', params) : this.jsonApiService.post('user/consumer/password', params);
    }
    
    getOneHourToken(tokenId: any): Observable<any> {
        return this.jsonApiService.fetch('session/one-hour-token/' + tokenId);
    }
    
    signOut() {
        sessionStorage.removeItem('identitySession');
        return this.jsonApiService.delete('session');
    }
}
