import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './fileupload.component';

@NgModule({
    imports: [CommonModule],
    declarations: [FileUploadComponent],
    exports: [FileUploadComponent]
})
export class FileUploadModule { }
